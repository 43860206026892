export const LOGIN_ROUTE_NAME = 'login';
export const LOGOUT_ROUTE_NAME = 'logout';
export const HOME_ROUTE_NAME = 'home';
export const ABOUT_ROUTE_NAME = 'about';
export const PRODUCTS_ROUTE_NAME = 'products';
export const CONTACT_ROUTE_NAME = 'contact';
export const USER_HOME_ROUTE_NAME = 'home';
const routeAllies = {
  landing: {
    home: HOME_ROUTE_NAME,
    about: ABOUT_ROUTE_NAME,
    products: PRODUCTS_ROUTE_NAME,
    contact: CONTACT_ROUTE_NAME,
  },
  auth: {
    login: LOGIN_ROUTE_NAME,
    logout: {
      path: `/${LOGOUT_ROUTE_NAME}`,
      name: LOGOUT_ROUTE_NAME
    }
  },
  user: {
    profile: 'profile',
    index: 'user-index',
  },
};
// console.log(routeAllies);
export default routeAllies;
