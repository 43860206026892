import routes, {ABOUT_ROUTE_NAME, CONTACT_ROUTE_NAME, PRODUCTS_ROUTE_NAME} from '@routes/config';
export default [
    {
        path: '',
        component: () => import( /* webpackChunkName: "App" */ '@views/App'),
        meta: {
            auth: true
        },
        children: [
            {
                path: routes.landing.home,
                name: routes.landing.home,
                component: () => import(/* webpackChunkName: "LandingHome" */ '@components/Home')
            },
            {
                path: routes.landing.about,
                name: routes.landing.about,
                component: () => import(/* webpackChunkName: "LandingAbout" */ '@components/About')
            },
            {
                path: routes.landing.products,
                name: routes.landing.products,
                component: () => import(/* webpackChunkName: "LandingProducts" */ '@components/Products')
            },
            {
                path: routes.landing.contact,
                name: routes.landing.contact,
                component: () => import(/* webpackChunkName: "LandingContact" */ '@components/Contact')
            },
        ]
    },
];
