<template>
  <v-app-bar
      v-bind="$attrs"
      v-on="$listeners"
      :clipped-left="!AppRtl"
      :clipped-right="AppRtl"
      class="text-light"
      style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgba(28,109,249,1) 0%,rgb(22,29,165,1) 100%) !important;"
      app
      color="primary"
  >
    <v-avatar :color="logoColor">
      <v-img
          :src="AppLogo2"
          contain
      ></v-img>
    </v-avatar>
    <v-toolbar-title class="ma-0 pa-0 ps-4">
      <router-link
          :to="route('home')"
          tag="span"
          class="pointer"
      > <v-btn style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgba(28,109,249,1) 0%,rgb(22,29,165,1) 100%) !important;">
        {{ $t('home') }}
      </v-btn>
      </router-link>
    </v-toolbar-title>
    <v-toolbar-title class="ma-0 pa-0 ps-4">
      <router-link
          :to="route('products')"
          tag="span"
          class="pointer"
      > <v-btn style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgba(28,109,249,1) 0%,rgb(22,29,165,1) 100%) !important;">
        {{ $t('products') }}
      </v-btn>
      </router-link>
    </v-toolbar-title>
    <v-toolbar-title class="ma-0 pa-0 ps-4">
      <router-link
          :to="route('about')"
          tag="span"
          class="pointer"
      > <v-btn style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgba(28,109,249,1) 0%,rgb(22,29,165,1) 100%) !important;">
        {{ $t('about') }}
      </v-btn>
      </router-link>
    </v-toolbar-title>
    <v-toolbar-title class="ma-0 pa-0 ps-4">
      <router-link
        :to="route('contact')"
        tag="span"
        class="pointer"
      > <v-btn style="color: #ffffffb3;background: -webkit-linear-gradient(top, rgba(28,109,249,1) 0%,rgb(22,29,165,1) 100%) !important;">
        {{ $t('contact') }}
      </v-btn>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="mx-1"></div>
  </v-app-bar>
</template>
<script>
export default {
  name: 'Bar',
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    logoColor() {
      return this.themeDark ? 'gray' : 'transparent';
    },
    showDrawerIcon() {
      return true;
    },
    drawer: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', Boolean(v));
      }
    },
    user() {
      return this.$root.authUser;
    }
  }
};
</script>
