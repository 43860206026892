<script>
import AxiosSelect from '@base-inputs/AxiosSelect';
const prefix = 'Resources';
const Resource = a => `${prefix}/${a}`;
export default {
  name: 'ClientSelect',
  extends: AxiosSelect,
  props: {
    clearable: {
      default: () => false
    },
    value: {},
    axiosUrl: {
      default: () => Resource('Clients')
    }
  },
};
</script>
