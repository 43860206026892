export default {
  q: '؟',
  sar: 'SAR',
  false: 'معطل',
  true: 'مفعل',
  progress: 'التقدم...',
  id: '#',
  ar: 'العربية',
  en: 'English',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  products: 'المنتجات',
  about: 'من نحن',
  contact: 'اتصل بنا',
  language: 'لغة العرض',
  done: 'موافق',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  enabled: 'مفعل',
  disabled: 'معطل',
  save: 'حفظ',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  store: 'إضافة',
  update: 'تعديل',
  destroy: 'حذف',
  delete: 'حذف',
  show: 'عرض',
  view: 'عرض',
  control: 'تحكم',
  none: 'لا يوجد',
  na: 'بدون',
  export: 'تصدير',
  close: 'إغلاق',
  edit: 'تعديل',
  add: 'إضافة',
  register: 'تسجيل',
  print: 'طباعة',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  exportPdf: 'تصدير البيانات PDF',
  exportExcel: 'تصدير البيانات Excel',
  ImportExcel: 'استيراد البيانات من Excel',
  refreshTable: 'تحديث الصفحة',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  year: 'سنة',
  showDetails: 'عرض التفاصيل',
  Details: 'تفاصيل ',
  information: 'معلومات ',
  setting: 'الضبط',
  month: 'شهر',
  non_defined: 'غير محدد',
  undefined: 'غير محدد',
  greg_letter: ' م',
  hijri_letter: ' هـ',
  import: 'استيراد',
  qty: "الكمية",
  price: "السعر",
  total: "الاجمالي",
  sr: " ريال",
  without: "بدون",
  rest: "متبقي",
  note: "ملاحظه: ",
  created_at: 'تاريخ الإضافة',
  updated_at: 'تاريخ التعديل',
  deleted_at: 'تاريخ الحذف',
  created_by: 'أضيف بواسطة',
  updated_by: 'التعديل بواسطة',
  deleted_by: 'حذف بواسطة',
  CarsWaiting: 'مركبات بانتظار التنازل',
  CarsSale: 'مركبات لائحة البيع',
  CarsAfter: 'مركبات تم بيعها',
};
