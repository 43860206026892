
export default {
  name: 'Name',
  name_hint: 'Name field required',
  name_ar: 'Arabic Name',
  name_ar_hint: 'Arabic Name field required',
  name_en: 'English Name',
  name_en_hint: 'English Name field required',
  login_id: 'UserName',
  login_id_hint: 'UserName field required',
  password: 'Password',
  password_hint: 'Password field required',
  password_confirmation: 'Password Confirmation',
  password_confirmation_hint: 'Password Confirmation field required',
  age: 'Age',
  age_hint: 'Age field required',
  birth_date: 'Birth Date',
  birth_date_hint: 'Birth Date field required',
  mobile: 'Mobile',
  mobile_hint: 'Mobile field required',
  sort_order: 'Sort order',
  active: 'Enabled',
  percent: 'Percentage',
  percentage: 'Percentage',
  status: 'Status',
  status_label: 'Status',
  other: 'Other',
  false: 'Disabled',
  true: 'Enabled',
  notes: 'Notes',
  notes_hint: 'Notes field required',
  type:'Type',
  type_hint:'Type field required',
  phone: 'Phone',
  phone_hint: 'Phone field required',
  email: 'Email',
  email_hint: 'Email field required',
  fax: 'Fax',
  fax_hint: 'Fax field required',
  download: 'Download',
  download_view: 'Download / View',
}
