

<template>
  <app-tooltip
      :disabled="tooltipDisabled"
      :text="getTooltip"
  >
    <v-btn
        v-bind="$attrs"
        v-on="$listeners"
        :class="getClasses"
        :min-width="minWidth"
        fab
        small
    >
      <template v-if="update"><v-icon color="success">edit</v-icon></template>
      <template v-if="show"><v-icon color="primary">visibility</v-icon></template>
      <template v-if="destroy"><v-icon color="error">delete</v-icon></template>
      <slot></slot>
    </v-btn>
  </app-tooltip>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    minWidth: {
      type: [String, Number],
      default: () => undefined
    },
    update: {
      type: Boolean,
      default: () => false
    },
    updateaddtext: {
      type: String,
      default: () => null
    },
    show: {
      type: Boolean,
      default: () => false
    },
    showaddtext: {
      type: String,
      default: () => null
    },
    destroy: {
      type: Boolean,
      default: () => false
    },
    destroyaddtext: {
      type: String,
      default: () => null
    },
    tooltip: {
      default: () => undefined
    }
  },
  computed: {
    getClasses: () => [
      'app-dt-btn',
      'my-2'
    ].join(' '),
    getMinWidth() {
      return this.$attrs.icon === undefined ? this.minWidth : undefined;
    },
    getTooltip() {
      if(this.tooltip) return this.parseAttribute(this.tooltip);
      if(this.update) return this.$t('update')+this.updateaddtext;
      if(this.show) return this.$t('show')+this.showaddtext;
      if(this.destroy) return this.$t('destroy')+this.destroyaddtext;
      return null;
    },
    tooltipDisabled() {
      return this.getTooltip === null || this.getTooltip === undefined;
    }
  }
};
</script>
