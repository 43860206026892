
export default {
  name: 'الاسم',
  name_hint: 'حقل الاسم مطلوب',
  name_ar: 'الاسم العربي',
  name_ar_hint: 'حقل الاسم العربي مطلوب',
  name_en: 'الاسم الإنجليزي',
  name_en_hint: 'حقل الاسم الإنجليزي مطلوب',
  login_id: 'اسم المسخدم',
  login_id_hint: 'حقل اسم المسخدم مطلوب',
  password: 'كلمة المرور',
  password_hint: 'حقل كلمة المرور مطلوب',
  password_confirmation: 'تأكيد كلمة المرور',
  password_confirmation_hint: 'حقل تأكيد كلمة المرور مطلوب',
  age: 'العمر',
  age_hint: 'حقل العمر مطلوب',
  birth_date: 'تاريخ الميلاد',
  birth_date_hint: 'حقل تاريخ الميلاد مطلوب',
  mobile: 'رقم الجوال',
  mobile_hint: 'رقم الجوال مطلوب',
  sort_order: 'ترتيب العرض',
  active: 'فعال',
  percent: 'النسبة',
  percentage: 'النسبة',
  status: 'الحالة',
  status_label: 'الحالة',
  other: 'غير ذلك',
  false: 'معطل',
  true: 'مفعل',
  notes: 'ملاحظات',
  notes_hint: 'حقل ملاحظات مطلوب',
  type:'النوع',
  type_hint:'حقل النوع مطلوب',
  phone: 'رقم الهاتف',
  phone_hint: 'حقل رقم الهاتف مطلوب',
  email: 'البريد الإلكتروني',
  email_hint: 'حقل البريد الإلكتروني مطلوب',
  fax: 'رقم الفاكس',
  fax_hint: 'حقل رقم الفاكس مطلوب',
  download: 'تحميل',
  download_view: 'تحميل / عرض',
}
